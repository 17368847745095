export const chartLabelFormatter = (
  tooltipItem,
  formatMoney,
  formatMoneyForLabel
) => {
  if (formatMoneyForLabel) {
    if (tooltipItem.dataset.value) {
      return `${tooltipItem.label}: ${formatMoney(
        tooltipItem.dataset.value[tooltipItem.dataIndex]
      )}`;
    } else {
      return `${tooltipItem.label}: ${formatMoney(tooltipItem.raw)}`;
    }
  } else {
    return `${tooltipItem.label}: ${tooltipItem.raw}`;
  }
};
